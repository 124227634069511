import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { SwiperOptions } from 'swiper';

import {
    grid,
    titleText,
    subtitleText,
    contentText,
    button,
    slider,
    prev,
    next,
    icon,
} from './people-slider.module.scss';
import { ISection } from '../../models/section.model';
import { IPeople } from '../../models/people.model';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IService } from '../../models/service.model';
import useTranslations from '../../hooks/use-translations';
import { useSwiperLoading } from '../../hooks/use-swiper-loading';
import { getNodes } from '../../utils/get-nodes';

import IconButton from '../atoms/icon-button';
import Slider from '../hoc/slider';
import Section from '../hoc/section';
import PeopleCard from '../molecules/people-card';

import ArrowLeftIcon from '../../assets/images/svg/arrow-left.svg';
import ArrowRightIcon from '../../assets/images/svg/arrow-right.svg';

interface IPeopleSliderSection extends ISection {
    extendedItems?: {
        service?: IService;
    };
}

interface IPeopleSliderProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: IPeopleSliderSection;
}

type TStaticQueryResult = {
    allPeople: IQueryAllResult<IPeople>;
};

const PeopleSlider: React.FC<IPeopleSliderProps> = ({
    className = '',
    section,
    TitleTag = 'h2',
}) => {
    const {
        sectionId,
        content: { texts, buttons },
        css,
        style,
        extendedItems,
    } = section;
    const { loading, navigationNextRef, navigationPrevRef } = useSwiperLoading();
    const t = useTranslations('ProductSlider');

    const { allPeople } = useStaticQuery<TStaticQueryResult>(query);
    let people = getNodes(allPeople);

    if (extendedItems?.service) {
        people = people.filter((person) => {
            return (
                person.services.filter((e) => e.serviceId === extendedItems.service?.serviceId)
                    .length > 0
            );
        });
    }

    if (people.length < 1) {
        return null;
    }

    return (
        <Section
            sectionId={sectionId}
            className={className}
            classes={{
                container: grid,
                title: titleText,
                subtitle: subtitleText,
                description: contentText,
                button: button,
            }}
            title={texts[0]}
            subtitle={texts[1]}
            description={texts[2]}
            css={css}
            style={style}
            buttonText={buttons[0]?.text}
            buttonUrl={buttons[0]?.url}
            TitleTag={TitleTag}
        >
            <div className={slider}>
                <IconButton className={prev} innerRef={navigationPrevRef} title={t.slidePrev}>
                    <ArrowLeftIcon className={icon} />
                </IconButton>
                <IconButton className={next} innerRef={navigationNextRef} title={t.slideNext}>
                    <ArrowRightIcon className={icon} />
                </IconButton>
                <Slider
                    loading={loading}
                    spaceBetween={50}
                    rewind={true}
                    navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    }}
                    breakpoints={sliderBreakpoints}
                >
                    {people.map((person) => {
                        return <PeopleCard key={`person-${person.personId}`} person={person} />;
                    })}
                </Slider>
            </div>
        </Section>
    );
};

const sliderBreakpoints: { [p: number]: SwiperOptions; [p: string]: SwiperOptions } = {
    0: {
        slidesPerView: 'auto',
        centeredSlides: true,
    },
    420: {
        slidesPerView: 'auto',
        centeredSlides: false,
    },
    1024: {
        slidesPerView: 3,
        centeredSlides: false,
    },
};

const query = graphql`
    query {
        allPeople {
            edges {
                node {
                    ...peopleShortFields
                }
            }
        }
    }
`;

export default PeopleSlider;
