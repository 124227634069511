import React from 'react';
import { Link } from 'gatsby';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    wrapper,
    imageLink,
    imageBox,
    imageRatio,
    nameLine,
    specLine,
    button,
} from './people-card.module.scss';

import { IPeople } from '../../models/people.model';
import Button from '../atoms/button';

interface IPeopleProps {
    className?: string;
    person: IPeople;
}

const PeopleCard: React.FC<IPeopleProps> = ({ className = '', person }) => {
    return (
        <div className={`${wrapper} ${className}`}>
            <Link className={imageLink} to={person.pathname}>
                <Image className={imageBox} ratioClass={imageRatio} media={person.media} />
            </Link>
            <p className={nameLine}>{`${person.title} ${person.firstName} ${person.lastName}`}</p>
            <p className={specLine}>{person.specialization}</p>
            <Button
                className={button}
                as={'link'}
                to={person.pathname}
                stylePreset={'main'}
                showPointer={false}
            >
                Zobacz profil
            </Button>
        </div>
    );
};

export default PeopleCard;
